<i18n>
{
	"en": {
		"title": "Memberships & magazine subscription",
		"description": ""
	},
	"fi": {
		"title": "Jäsenyydet & lehden tilaus",
		"description": "Voit tehdä muutoksia jäsenyyteen kuuluvaan lehteen napsauttamalla jäsenyyttäsi."
	},
	"sv": {
		"title": "Medlemskap & prenumeration",
		"description": "Du kan göra ändringar i tidningsprenumerationen, som ingår i medlemskapet. Klicka på ditt medlemskap."
	}
}
</i18n>

<template>
	<div>
		<template v-if="($route.name === 'subscriptionContainer')">
			<v-container class="container--narrow">
				<v-card>
					<v-card-title>
						<h3
							v-if="user.membership.length"
							class="text-h6"
						>
							{{ $i18n.t('title') }}
						</h3>
					</v-card-title>
					<v-card-text>
						{{ $i18n.t('description') }}
					</v-card-text>
					<FilterableList
						v-if="user.membership.length"
						:items="user.membership"

						title-src="title"
						:enable-search="false"

						@itemClick="itemClick"
					/>
				</v-card>
			</v-container>
		</template>
		<template v-else>
			<router-view />
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'SubscriptionContainer',

	computed: {
		...mapState({
			user: state => state.user,
		}),
	},

	methods: {
		itemClick (item) {
			this.$router.push({ name: 'subscription', params: { subscriptionId: item.id } })
		},
	},
}
</script>
