import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en.json'
import fi from './fi.json'
import sv from './sv.json'

Vue.use(VueI18n)

const messages = {
	en: en,
	fi: fi,
	sv: sv,
}

const i18n = new VueI18n({
	locale: 'fi',
	fallbackLocale: 'en',
	silentTranslationWarn: true,
	messages,
})

export default i18n
