<template>
	<div class="text-center">
		<v-progress-circular
			indeterminate
			color="primary"
		/>
		<p
			v-if="text"
			class="mt-6 text--sm"
		>
			{{ text }}
		</p>
	</div>
</template>

<script>

export default {
	name: 'Spinner',
	props: {
		text: {
			type: String,
			default: () => {
				return ''
			},
		},
	},
}
</script>
