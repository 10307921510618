<i18n>
{
	"en": {
		"actions": {
			"logout": "Log out"
		},
		"headings": {
			"application": "Application",
			"social": "Finnish Diabetes Association in social media"
		}
	},
	"fi": {
		"actions": {
			"logout": "Kirjaudu ulos"
		},
		"headings": {
			"application": "Sovellus",
			"social": "Diabetesliiton Some"
		}
	},
	"sv": {
		"actions": {
			"logout": "Logga ut"
		},
		"headings": {
			"application": "App",
			"social": "Diabetesförbundets Sociala Medier"
		}
	}
}
</i18n>

<template>
	<v-list
		dense
		nav
	>
		<v-list-item-group color="primary">
			<v-subheader
				class="text-uppercase"
			>
				{{ $i18n.t('headings.application') }}
			</v-subheader>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'myAccount')"
				:to="({ name: 'myAccount' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-account
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.myAccount.title') }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'settings')"
				:to="({ name: 'settings' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-cog
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.settings.title') }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item @click="$emit('logout')">
				<v-list-item-icon>
					<v-icon>
						mdi-logout
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('actions.logout') }}
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>
		<v-list-item-group
			v-if="config.social_media_links.length"
			color="primary"
		>
			<v-subheader class="text-uppercase">
				{{ $i18n.t('headings.social') }}
			</v-subheader>
			<v-list-item
				v-for="(item, index) in config.social_media_links"
				:key="index"
				:href="item.url"
				target="_blank"
			>
				<v-list-item-icon v-if="item.icon">
					<v-icon>
						{{ item.icon }}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content v-if="item.label">
					{{ item.label }}
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>
	</v-list>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'OffCanvasNav',
	computed: {
		...mapState([
			'config',
		]),
	},
}

</script>

<style lang="scss" scoped>

</style>
