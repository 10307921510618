<i18n>
{
	"en": {
		"confirmNavigationDialog": {
			"text": "Your unsaved edits will be discarded.",
			"title": "Leave page?"
		},
		"errorScreen": {
			"logoutButtonText": "Log out",
			"retryButtonText": "Try again",
			"title": "Error"
		},
		"loadingErrorTexts": {
			"benefits": "Failed to load benefits!",
			"events": "Failed to load events!",
			"news": "Failed to load news!",
			"pages": "Failed to load pages!",
			"settings": "Failed to load settings!",
			"userDetails": "Failed to load user details!"
		},
		"loadingTexts": {
			"benefits": "Loading benefits",
			"events": "Loading events",
			"news": "Loading news",
			"pages": "Loading pages",
			"settings": "Loading settings",
			"userDetails": "Loading user details"
		},
		"logoutDialog": {
			"errorText": "Failed to log out!",
			"loadingText": "Logging out...",
			"title": "Log out?"
		}
	},
	"fi": {
		"confirmNavigationDialog": {
			"text": "Tallentamattomat muutokset menetetään.",
			"title": "Poistu sivulta?"
		},
		"errorScreen": {
			"logoutButtonText": "Kirjaudu ulos",
			"retryButtonText": "Yritä uudelleen",
			"title": "Virhe"
		},
		"loadingErrorTexts": {
			"benefits": "Jäsenetujen lataaminen ei onnistunut!",
			"events": "Tapahtumien lataaminen ei onnistunut!",
			"news": "Uutisten lataaminen ei onnistunut!",
			"pages": "Sivujen lataaminen ei onnistunut!",
			"settings": "Asetusten lataaminen ei onnistunut!",
			"userDetails": "Käyttäjätietojen lataaminen ei onnistunut!"
		},
		"loadingTexts": {
			"benefits": "Ladataan jäsenetuja",
			"events": "Ladataan tapahtumia",
			"news": "Ladataan uutisia",
			"pages": "Ladataan sivuja",
			"settings": "Ladataan asetuksia",
			"userDetails": "Ladataan käyttäjätietoja"
		},
		"logoutDialog": {
			"errorText": "Uloskirjautuminen epäonnistui!",
			"loadingText": "Kirjaudutaan ulos...",
			"title": "Kirjaudu ulos?"
		}
	},
	"sv": {
		"confirmNavigationDialog": {
			"text": "Icke sparade ändringar försvinner.",
			"title": "Lämna sidan?"
		},
		"errorScreen": {
			"logoutButtonText": "Logga ut",
			"retryButtonText": "Försök på nytt",
			"title": "Fel"
		},
		"loadingErrorTexts": {
			"benefits": "Kunde inte ladda ner medlemsförmåner!",
			"events": "Kunde inte ladda ner händelser!",
			"news": "Kunde inte ladda ner nyheter!",
			"pages": "Kunde inte ladda ner sidorna!",
			"settings": "Kunde inte ladda ner inställningarna!",
			"userDetails": "Kunde inte ladda ner användardata!"
		},
		"loadingTexts": {
			"benefits": "Laddar ner medlemsförmåner",
			"events": "Laddar ner händelser",
			"news": "Laddar ner nyheter",
			"pages": "Laddar ner sidor",
			"settings": "Laddar ner inställningar",
			"userDetails": "Laddar ner användardata"
		},
		"logoutDialog": {
			"errorText": "Kunde inte logga ut!",
			"loadingText": "Loggar ut…",
			"title": "Logga ut?"
		}
	}
}
</i18n>

<template>
	<v-app>
		<!-- Error screen -->
		<v-layout
			v-if="error && Object.keys(error).length"
			align-center
			justify-center
		>
			<div class="pa-12 text-center">
				<h1>
					{{ error.title || $i18n.t('errorScreen.title') }}
				</h1>
				<p
					v-if="error.summary"
					class="mt-3"
				>
					{{ error.summary }}
				</p>
				<v-btn
					v-if="error.buttonTitle && (error.buttonHref || error.buttonAction)"
					color="primary"
					class="ma-3"
					@click="errorButtonClick"
				>
					{{ error.buttonTitle }}
				</v-btn>
				<v-btn
					color="error"
					class="ma-3"
					@click="logout"
				>
					{{ $i18n.t('errorScreen.logoutButtonText') }}
				</v-btn>
			</div>
		</v-layout>

		<!-- Loading screen -->
		<v-layout
			v-else-if="loading"
			align-center
			justify-center
		>
			<Spinner
				class="pa-12"
				:text="loadingText"
			/>
		</v-layout>

		<!-- Main view -->
		<template
			v-else
		>
			<!-- Top bar -->
			<TopBar
				v-if="ready"
				:headline="headline"
				@menu-button-click="drawerOpen = !drawerOpen"
			/>

			<!-- Page content -->
			<v-main>
				<router-view />
			</v-main>

			<!-- Off-canvas drawer -->
			<v-navigation-drawer
				v-if="ready"
				v-model="drawerOpen"
				app
				temporary
				right
				touchless
			>
				<OffCanvasNav @logout="logoutDialog = true; drawerOpen = false" />
			</v-navigation-drawer>

			<!-- Bottom navigation bar -->
			<BottomBar v-if="ready" />
		</template>

		<!-- Navigation confirm dialog -->
		<v-dialog
			v-model="confirmNavigationDialog"
			max-width="300"
		>
			<v-card>
				<v-card-title class="headline justify-center">
					{{ $i18n.t('confirmNavigationDialog.title') }}
				</v-card-title>
				<v-card-text class="text-center">
					{{ $i18n.t('confirmNavigationDialog.text') }}
				</v-card-text>
				<v-card-actions class="justify-center">
					<v-btn
						color="success"
						text
						@click="confirmNavigationDialog = false"
					>
						{{ $i18n.t('general.cancel') }}
					</v-btn>
					<v-btn
						color="error"
						text
						@click="confirmNavigation"
					>
						{{ $i18n.t('general.yes') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Logout dialog -->
		<v-dialog
			v-model="logoutDialog"
			max-width="300"
		>
			<v-card>
				<v-card-title class="headline justify-center">
					{{ $i18n.t('logoutDialog.title') }}
				</v-card-title>
				<v-card-actions class="justify-center">
					<v-btn
						color="success"
						text
						@click="logoutDialog = false"
					>
						{{ $i18n.t('general.cancel') }}
					</v-btn>
					<v-btn
						color="error"
						text
						@click="logout"
					>
						{{ $i18n.t('general.yes') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Cookie consent dialog -->
		<v-dialog
			v-if="loading === false"
			v-model="cookieDialog"
			max-width="400"
			persistent
			scrollable
		>
			<CookieDialog
				v-if="cookieDialog === true"
				@consent="consent"
			/>
		</v-dialog>

		<Notifications />
	</v-app>
</template>

<script>
import { mapState } from 'vuex'
import TopBar from '@/components/TopBar'
import BottomBar from '@/components/BottomBar'
import OffCanvasNav from '@/components/OffCanvasNav'
import Notifications from '@/components/Notifications'
import CookieDialog from '@/components/CookieDialog'

export default {
	name: 'App',
	components: {
		TopBar,
		BottomBar,
		OffCanvasNav,
		Notifications,
		CookieDialog,
	},
	data: () => ({
		ready: false, // Is app ready?
		loading: true, // Is app loading?
		loadingText: '', // Loading description text
		headline: '', // Custom headline shown in top bar
		updateAvailable: false, // Is update available?
		registration: null, // Registration for service worker
		drawerOpen: false, // Is off-canvas drawer open?
		logoutDialog: false, // Is logout dialog open?
		cookieDialog: false, // Is cookie consent dialog open?
	}),
	computed: {
		...mapState(['error',
			'config',
			'settings',
			'user',
		]),
		confirmNavigationDialog: {
			get () {
				return this.$store.state.confirmNavigationDialog
			},
			set (val) {
				this.$store.commit('setConfirmNavigationDialog', val)
			},
		},
	},
	watch: {
		// Monitor settings
		settings: {
			deep: true,
			handler (val) {
				// Save settings to store
				this.$store.dispatch('setSettings', val)

				// Set locale
				this.$root.$i18n.locale = val.locale

				// Switch light/dark mode
				this.$vuetify.theme.dark = val.darkMode

				// Cookie settings
				if (val.acceptedCookies) {
					if (val.acceptedCookies.includes('statistics')) {
						this.$gtag.optIn()
					} else {
						this.$gtag.optOut()
					}
				}
			},
		},

		// Monitor route changes
		$route: {
			deep: true,
			immediate: true,
			handler (to) {
				// Define headline based on current route
				const headlineKey = 'routes.' + to.name + '.headline'
				this.headline = (this.$i18n.t(headlineKey) != headlineKey) ? this.$i18n.t(headlineKey) : null

				// Define document title based on current route
				const documentTitleKey = 'routes.' + to.name + '.title'
				this.setDocumentTitle((this.$i18n.t(documentTitleKey) != documentTitleKey) ? this.$i18n.t(documentTitleKey) : null)
			},
		},
	},
	async mounted () {
		this.handleCaptchaReturn()
		// Load user settings from localStorage
		await this.$store.dispatch('getSettings')

		// Show cookie dialog if necessary
		this.cookieDialog =
      process.env.VUE_APP_COOKIE_CONSENT === 'true' &&
      this.settings.showCookieDialog === true

		// Initialize app
		this.init()
	},
	async created () {
		// When service worker has an update available, notify user
		document.addEventListener('swUpdated', (e) => {
			this.$store.commit('setUpdateAvailable', true)
			this.registration = e.detail
		})

		// Set custom headline, if requested by view component
		this.$root.$off('setHeadline')
		this.$root.$on('setHeadline', val => {
			this.$nextTick(() => {
				this.setDocumentTitle(val)
				this.headline = val
			})
		})

		// When locale changes, reload all data
		this.$root.$off('localeChanged')
		this.$root.$on('localeChanged', () => {
			this.init(true)
		})

		// Open cookie dialog, if requested by view component
		this.$root.$off('openCookieDialog')
		this.$root.$on('openCookieDialog', () => {
			this.cookieDialog = true
		})
	},
	methods: {
		// Initialize app
		init (clearCache = false) {
			this.loadData(clearCache)
				.then(() => {
					this.ready = true
				})
				.catch((error) => {
					if (error.result && error.result.status != 500) {
						this.$store.commit('setError', {
							summary: error.summary,
							buttonTitle: this.$i18n.t('errorScreen.retryButtonText'),
							buttonAction: this.init,
						})
					}
				})
				.finally(() => {
					this.loading = false
				})
		},

		// Load all app data
		async loadData (clearCache = false) {
			this.ready = false
			this.loading = true
			this.$store.commit('clearError')

			// Query params
			let query = {}

			// If clearing cache is requested
			// For example, this is mandatory when user changes language
			if (clearCache === true) {
				query.clearCache = 1
			}

			let request

			this.loadingText = this.$i18n.t('loadingTexts.settings')
			request = await this.$api.Config.pushRequest({ query: query })
			if (!request.success)	return Promise.reject({
				result: request.result,
				summary: this.$i18n.t('loadingErrorTexts.settings'),
			})

			this.loadingText = this.$i18n.t('loadingTexts.userDetails')
			request = await this.$api.Me.pushRequest({ query: query })
			if (!request.success)	return Promise.reject({
				result: request.result,
				summary: this.$i18n.t('loadingErrorTexts.userDetails'),
			})

			this.loadingText = this.$i18n.t('loadingTexts.news')
			request = await this.$api.News.pushRequest({ query: query })
			if (!request.success)	return Promise.reject({
				result: request.result,
				summary: this.$i18n.t('loadingErrorTexts.news'),
			})

			// this.loadingText = this.$i18n.t('loadingTexts.events')
			// request = await this.$api.Events.pushRequest({ query: query })
			// if (!request.success)	return Promise.reject({
			// 	result: request.result,
			// 	summary: this.$i18n.t('loadingErrorTexts.events'),
			// })

			this.loadingText = this.$i18n.t('loadingTexts.benefits')
			request = await this.$api.Benefits.pushRequest({ query: query })
			if (!request.success) return Promise.reject({
				result: request.result,
				summary: this.$i18n.t('loadingErrorTexts.benefits'),
			})

			this.loadingText = this.$i18n.t('loadingTexts.pages')
			request = await this.$api.Pages.pushRequest({ query: query })
			if (!request.success) return Promise.reject({
				result: request.result,
				summary: this.$i18n.t('loadingErrorTexts.pages'),
			})

			return Promise.resolve()
		},

		// Log user out
		async logout () {
			this.logoutDialog = false
			this.loading = true
			this.loadingText = this.$i18n.t('logoutDialog.loadingText')

			// Do logout request
			await this.$api.Logout.pushRequest().on('error', () => {
				this.loading = false
				this.error = this.$i18n.t('logoutDialog.errorText')
			})
		},

		// Error button click handler
		errorButtonClick () {
			if (this.error && Object.keys(this.error).length) {
				if (typeof this.error.buttonAction == 'function') {
					this.error.buttonAction()
				} else if (this.error.buttonHref) {
					window.location.href = this.error.buttonHref
				}
			}
		},

		// When user decides to exit current route even when there are unsaved changes
		confirmNavigation () {
			let route = Object.assign({}, this.$store.state.confirmNavigationRoute)

			this.$store.dispatch('clearConfirmNavigation')
			this.$router.push(route)
		},

		// Cookie consent
		consent (acceptedCookies = []) {
			// Save cookie settings
			this.$store.dispatch('setSettings', {
				...this.settings,
				showCookieDialog: false, // Don't show dialog in future
				acceptedCookies: acceptedCookies,
			})

			// Close cookie dialog
			this.cookieDialog = false
		},

		// Set document title
		setDocumentTitle (val = null) {
			document.title = val
		},
		handleCaptchaReturn () {
			const captchaReturnUrl = sessionStorage.getItem('captcha_return_url')
			if (!captchaReturnUrl) return

			sessionStorage.removeItem('captcha_return_url')

			if (captchaReturnUrl == window.location) return

			window.location.href = captchaReturnUrl
		},
	},
}
</script>

<style lang="scss">
.container {
  &--narrow {
    max-width: 700px;
  }
}

// Multi-line list texts
.v-list-item-title--multiline,
.v-list-item-subtitle--multiline {
	-webkit-line-clamp: unset !important;
	overflow: visible !important;
	white-space: normal !important;
}

// Required fields
.v-input {
  &--required {
    .v-label {
      &:after {
        content: ' *';
      }
    }
  }
}

// CKEditor content
.rte {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  p,
  .p,
  ul,
  ol,
  .tablewrapper {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    margin: 1.5em 0 1rem 0;
  }

  h3 {
    margin: 1.5em 0 1rem 0;
  }

  h4 {
    margin: 1.5em 0 0.5rem 0;
  }

  p,
  ul,
  ol {
    margin-bottom: 1em;
  }

  table {
    width: auto !important;
    max-width: 100%;
  }

  th,
  td {
    padding: 0.5em;
    vertical-align: top;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0 0 1rem 0;

    &.align_left,
    &.align_right {
      width: 40%;
    }

    &.align_left {
      float: left;
      margin-right: 1rem;
    }

    &.align_right {
      float: right;
      margin-left: 1rem;
    }
  }

  // Clearfix
  &:after {
    content: ' ';
    display: block;
    float: none;
  }

  // Horizontally scrollable tables
  .tablewrapper {
    width: 100%;
    max-width: 100%;
    overflow-y: visible;
    overflow-x: auto;
    margin: 2rem 0;
  }

  table {
    max-width: 100%;
    border-collapse: collapse;
    border: none;
  }
}

.no-list {
	list-style: none;
	padding-left: 0 !important;
}

.no-line-break {
  white-space: nowrap;
}

// Temporary fix for bottom navigation bug
.v-item-group.v-bottom-navigation .v-btn.v-size--default {
  height: inherit;
}

// Fix word breaking in cards
.v-card__text,
.v-card__title {
  word-break: normal !important;
}

// Cards in dialogs
.v-dialog {
  .v-card {
    position: relative;
  }

  .v-card__title {
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  }

  .v-card__actions {
    border-top: solid 1px rgba(0, 0, 0, 0.12);
  }

  .v-card__text {
    z-index: 1;
    padding-top: 20px !important;
  }
}

// Disabled input fields
.theme--light {
	.v-input {
		&--is-disabled {
			color: rgba(0, 0, 0, 0.65);

			.v-label,
			input,
			textarea {
				color: rgba(0, 0, 0, 0.65);
			}
		}
	}
}
</style>
