<i18n>
{
	"en": {
		"myDetails": "My account",
		"invoices": "Invoices",
		"subscription": "Magazine subscription",
		"editFormNotification": "Your address details will be automatically updated from Posti address register. If any of these details are incorrect, please contact us."
	},
	"fi": {
		"myDetails": "Omat tiedot",
		"invoices": "Laskut",
		"subscription": "Lehden tilaus",
		"editFormNotification": "Postiosoite päivittyy automaattisesti Postin osoiterekisterin kautta. Mikäli tiedoissa on korjattavaa, olethan yhteydessä liittoon."
	},
	"sv": {
		"myDetails": "Egna uppgifter",
		"invoices": "Räkningar",
		"subscription": "Tidningsprenumeration",
		"editFormNotification": "Postadressen uppdateras automatiskt via Postens adressregister. Kontakta förbundet för eventuella korrigeringar"
	}
}
</i18n>

<template>
	<div>
		<v-toolbar
			color="primary"
			dark
			flat
		>
			<v-tabs
				v-model="currentTab"
				centered
				center-active
				show-arrows
			>
				<v-tabs-slider />
				<v-tab
					v-for="tab in tabs"
					:key="tab.name"
					:to="tab.route"
					exact-path
				>
					{{ $i18n.t(tab.title) }}
				</v-tab>
			</v-tabs>
		</v-toolbar>

		<template v-if="($route.name === 'myAccount')">
			<v-container
				v-if="ready === false"
				class="container--narrow"
			>
				<Spinner />
			</v-container>

			<v-container
				v-else
				class="container--narrow"
			>
				<v-card v-if="config.forms && config.forms.myAccount">
					<v-card-text>
						<v-alert
							type="info"
							class="mb-8"
						>
							{{ $i18n.t('editFormNotification') }}
						</v-alert>
						<SchemaToForm
							v-model="formData"
							:schema="config.forms.myAccount"
							:disabled="saveLoading"
							:debug="false"
							@valid="formValid = true"
							@invalid="formValid = false"
							@change="$store.commit('setConfirmNavigation', true)"
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn
							color="primary"
							:loading="saveLoading"
							:disabled="saveLoading || !formValid"
							@click="save"
							text
						>
							{{ $i18n.t('general.save') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-container>
		</template>

		<template v-else>
			<router-view :key="$route.fullPath" />
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MyAccount',

	data: () => ({
		ready: false,
		formData: {},
		saveLoading: false,
		formValid: false,
		currentTab: 0,
		tabs: [
			{
				name: 'details',
				title: 'myDetails',
				route: '/myaccount',
			},
			{
				name: 'invoices',
				title: 'invoices',
				route: '/myaccount/invoices',
			},
			{
				name: 'subscription',
				title: 'subscription',
				route: '/myaccount/subscriptions',
			},
		],
	}),

	computed: {
		...mapState([
			'user',
			'config',
		]),
	},

	mounted () {
		this.$api.Me.doRequest().then(() => {
			this.formData = JSON.parse(JSON.stringify(this.user))
			this.ready = true
		})
	},

	methods: {
		save () {
			this.saveLoading = true

			this.$api.Me.doRequest({
				method: 'POST',
				body: this.formData,
			}).on('done', (res) => {
				// Since the register may reformat values (i.e. telephone number),
				// replace form data with server response.
				if (res.body.item) {
					this.formData = res.body.item
				}

				// Clear navigation confirm
				if (!res.body.error){
					this.$nextTick(() => {
						this.$store.dispatch('clearConfirmNavigation')
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
}
</script>
