<i18n>
{
	"en": {
		"invoices": "Invoices",
		"noInvoicesMessage": "No invoices to show."
	},
	"fi": {
		"invoices": "Laskut",
		"noInvoicesMessage": "Laskuja ei löytynyt."
	},
	"sv": {
		"invoices": "Räkningar",
		"noInvoicesMessage": "Inga fakturor hittades."
	}
}
</i18n>

<template>
	<div>
		<template v-if="($route.name === 'invoiceContainer')">
			<v-container
				v-if="ready === false"
				class="container--narrow"
			>
				<Spinner />
			</v-container>
			<v-container
				v-else
				class="container--narrow"
			>
				<v-card>
					<v-card-title>
						<h3
							v-if="invoices.length"
							class="text-h6"
						>
							{{ $i18n.t('invoices') }}
						</h3>
					</v-card-title>
					<FilterableList
						v-if="invoices.length"
						:items="invoices"

						title-src="_list_title"
						summary-src="_list_summary"
						icon-src="_icon"
						icon-color-src="_icon_color"

						:enable-search="false"

						@itemClick="itemClick"
					/>
					<v-alert
						v-else
						type="info"
					>
						{{ $i18n.t('noInvoicesMessage') }}
					</v-alert>
				</v-card>
			</v-container>
		</template>
		<template v-else>
			<router-view />
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'InvoiceContainer',

	data: () => ({
		ready: true,
	}),

	computed: {
		...mapState({
			invoices: state => state.user.invoice,
		}),
	},

	methods: {
		itemClick (item) {
			this.$router.push({ name: 'invoice', params: { invoiceId: item.id } })
		},
	},
}
</script>
