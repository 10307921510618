<i18n>
{
	"fi": {
		"help": "Tallentamasi muutokset kohdistuvat ensi vuoden tilaukseen. Jos haluat muuttaa kuluvan vuoden tilausta, ole yhteydessä:",
		"label": "Nykyinen tilausvalintasi:",
		"membershipWithoutMagazineNotification": "Jäsenyyteesi ei kuulu lehteä. Voit tilata lehden ensi vuodelle.",
		"membershipWithMagazineNotification": "Olet tilannut lehden. Tilauksesi jatkuu ennallaan, ellet tee muutoksia.",
		"membershipNewNotification": "Lehti kuuluu jäsenyyteesi kuluvan vuoden ajan. Valitse, haluatko lehteä ensi vuonna."
	},
	"sv": {
		"help": "Sparade ändringar gäller nästa års prenumeration. Om du vill ändra detta års prenumeration, kontakta då:",
		"label": "Din nuvarande prenumeration:"
	}
}
</i18n>

<template>
	<v-container class="container--narrow">
		<v-card v-if="membership">
			<v-card-title>
				{{ membership.title }}
			</v-card-title>
			<v-card-subtitle v-if="membership.c_magazine_date">
				Tilaus päivitetty: {{ new Date(membership.c_magazine_date).toLocaleDateString() }}
			</v-card-subtitle>
			<v-divider />
			<v-card-text>
				<p>
					{{ $i18n.t('help') }}
					<a href="mailto:jasenasiat@diabetes.fi">jasenasiat@diabetes.fi</a>.
				</p>
				<p
					v-if="membership.c_magazine_date"
					class="mb-6"
				>
					{{ $i18n.t('label') }}
				</p>
				<v-alert
					v-if="!membership.c_magazine_date && membershipWithoutMagazine.includes(membership.membership_type)"
					type="info"
				>
					{{ $i18n.t('membershipWithoutMagazineNotification') }}
				</v-alert>

				<v-alert
					v-if="!membership.c_magazine_date && membershipWithMagazine.includes(membership.membership_type)"
					type="info"
				>
					{{ $i18n.t('membershipWithMagazineNotification') }}
				</v-alert>

				<v-alert
					v-if="!membership.c_magazine_date && membershipNew.includes(membership.membership_type)"
					type="info"
				>
					{{ $i18n.t('membershipNewNotification') }}
				</v-alert>
				<SchemaToForm
					v-model="formData"
					:schema="config.forms.subscription"
					:disabled="saveLoading"
					:debug="false"
					@valid="formValid = true"
					@invalid="formValid = false"
					@change="$store.commit('setConfirmNavigation', true)"
				/>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-spacer />
				<v-btn
					color="primary"
					:loading="saveLoading"
					:disabled="saveLoading || !formValid"
					@click="save"
					text
				>
					{{ $i18n.t('general.save') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'Subscription',

	data: () => ({
		membership: null,
		ready: false,
		formData: {},
		saveLoading: false,
		formValid: false,
		// membership types
		membershipWithoutMagazine: ['o_633', 'o_734'], // normaali jäsen o_633, vapaa jäsen o_734
		membershipWithMagazine: ['normaali-jasen-jasenlehti', 'vapaa-jasen-jasenlehti'],
		membershipNew: ['o_723'], // uusi jäsen o_723
	}),

	computed: {
		...mapState({
			config: state => state.config,
			user: state => state.user,
		}),
	},

	mounted () {
		// Find current item from the store
		this.membership = this.user.membership.find(item => {
			return item.id == this.$route.params.subscriptionId
		})

		if (!this.membership) {
			this.$router.replace({ name: 'error404' })
		}

		this.formData = {
			c_diabetes_magazine: this.membership.c_diabetes_magazine,
			c_magazine_date: new Date().toISOString().split('T')[0],
		}
	},

	methods: {
		save () {
			this.saveLoading = true

			// console.log({ ...this.formData, membership_id: this.membership.id })

			this.$api.Memberships.doRequest({
				url: 'subscription',
				method: 'POST',
				body: {
					...this.formData,
					membership_id: this.membership.id,
				},
			}).on('done', (res) => {
				if (!res.body.error) {
					this.$store.dispatch('clearConfirmNavigation')
					this.$router.replace({
						name: this.$route.matched[this.$route.matched.length - 2].name,
					})
				}
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
}
</script>
