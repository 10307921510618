<i18n>
{
	"en": {
		"inputLabel": "Search",
		"noResultsText": "Your search didn't match any results."
	},
	"fi": {
		"inputLabel": "Haku",
		"noResultsText": "Ei hakutuloksia."
	},
	"sv": {
		"inputLabel": "Sök",
		"noResultsText": "Sökningen gav inga resultat"
	}
}
</i18n>

<template>
	<div v-if="items && items.length">
		<v-sheet
			v-if="enableSearch"
			class="pt-6 pb-3"
		>
			<v-text-field
				v-model="searchQuery"
				:label="searchLabel || $i18n.t('inputLabel')"
				:hide-details="(true)"
				prepend-inner-icon="mdi-magnify"
				rounded
				clearable
				filled
			/>
		</v-sheet>

		<v-list v-if="filteredItems.length">
			<template v-for="(item, index) in filteredItems">
				<v-list-item
					:key="item.id"
					:href="(item[redirectUrlSrc]) ? item[redirectUrlSrc] : null"
					:target="(item[redirectUrlSrc]) ? '_blank' : null"
					v-on="enableClick ? { click: () => itemClick(item) } : {}"
				>
					<v-list-item-icon v-if="item[iconSrc]">
						<v-icon :color="(item[iconColorSrc]) ? item[iconColorSrc] : 'secondary'">
							{{ item[iconSrc] }}
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<template v-if="!$scopedSlots.content">
							<v-list-item-subtitle
								v-if="item[subtitleSrc]"
								:class="[
									multiline ? 'v-list-item__subtitle--multiline' : null
								]"
								v-html="item[subtitleSrc]"
							/>
							<v-list-item-title
								v-if="item[titleSrc]"
								:class="[
									multiline ? 'v-list-item__title--multiline' : null
								]"
								v-html="item[titleSrc]"
							/>
							<v-list-item-subtitle
								v-if="item[summarySrc]"
								:class="[
									multiline ? 'v-list-item__subtitle--multiline' : null
								]"
								v-html="item[summarySrc]"
							/>
						</template>
						<slot
							name="content"
							:item="item"
						/>
					</v-list-item-content>
					<v-list-item-action
						v-if="item[metaSrc] || !!$scopedSlots.meta"
					>
						<v-list-item-action-text
							v-if="!$scopedSlots.meta"
							v-html="item[metaSrc]"
						/>
						<slot
							name="meta"
							:item="item"
						/>
					</v-list-item-action>
				</v-list-item>
				<v-divider
					v-if="index != filteredItems.length - 1"
					:key="('divider-' + index)"
					:inset="(item.icon && item.icon.length > 0)"
				/>
			</template>
		</v-list>
		<p
			v-else
			class="pa-6"
		>
			{{ noResultsText || $i18n.t('noResultsText') }}
		</p>
	</div>
</template>

<script>

export default {
	name: 'FilterableList',
	props: {
		// An array of items to render
		items: {
			type: Array,
			required: false,
			default: () => {
				return []
			},
		},

		// Enable multi-line texts?
		multiline: {
			type: Boolean,
			required: false,
			default () {
				return false
			},
		},

		// Item property to use for item icon
		iconSrc: {
			type: String,
			required: false,
			default: () => {
				return 'icon'
			},
		},

		// Item property to use for item icon color
		iconColorSrc: {
			type: String,
			required: false,
			default: () => {
				return 'icon_color'
			},
		},

		// Item property to use for item title
		titleSrc: {
			type: String,
			required: false,
			default: () => {
				return 'title'
			},
		},

		// Item property to use for item subtitle
		subtitleSrc: {
			type: String,
			required: false,
			default: () => {
				return 'subtitle'
			},
		},

		// Item property to use for item summary
		summarySrc: {
			type: String,
			required: false,
			default: () => {
				return 'summary'
			},
		},

		// Item property to use for item meta
		// Use this for dates etc.
		metaSrc: {
			type: String,
			required: false,
			default: () => {
				return 'date'
			},
		},

		// Item property to use for item redirect url
		// If redirect url is defined, user will be redirected
		// when clicking list item.
		redirectUrlSrc: {
			type: String,
			required: false,
			default: () => {
				return 'redirect_url'
			},
		},

		// Allow user to click list items?
		enableClick: {
			type: Boolean,
			required: false,
			default: () => {
				return true
			},
		},

		// Enable search?
		enableSearch: {
			type: Boolean,
			required: false,
			default: () => {
				return true
			},
		},

		// Label for search input
		searchLabel: {
			type: String,
			required: false,
			default: () => {
				return null
			},
		},

		// Text to display when there are no results to show
		noResultsText: {
			type: String,
			required: false,
			default: () => {
				return null
			},
		},
	},
	data: () => ({
		searchQuery: '',
	}),
	computed: {
		// Return filtered items. We use this var to render list items.
		filteredItems () {
			if (this.searchQuery) {
				let regex = new RegExp(this.searchQuery, 'i')

				return this.items.filter(item => {
					if (
						regex.test(item[this.titleSrc]) ||
						regex.test(item[this.subtitleSrc]) ||
						regex.test(item[this.summarySrc])
					) return true
				})
			} else {
				return this.items
			}
		},
	},
	methods: {
		// Item click handler
		itemClick (item) {
			if (item[this.redirectUrlSrc]) return

			this.$emit('itemClick', item)
		},
	},
}
</script>
<style lang="scss">

.v-list-item__title--multiline,
.v-list-item__subtitle--multiline {
	overflow: visible;
	white-space: normal;
}

</style>
