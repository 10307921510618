<i18n>
{
	"en": {
		"associationBenefits": "Association benefits",
		"unionBenefits": "Union benefits",
		"noItemsMessage": "No benefits to show."
	},
	"fi": {
		"associationBenefits": "Yhdistyksen edut",
		"unionBenefits": "Liiton edut",
		"noItemsMessage": "Jäsenetuja ei löytynyt."
	},
	"sv": {
		"associationBenefits": "Föreningens förmåner",
		"unionBenefits": "Förbundets förmåner",
		"noItemsMessage": "Inga medlemsförmånader hittades"
	}
}
</i18n>

<template>
	<div>
		<router-view />

		<template v-if="($route.name == 'benefitContainer')">
			<v-toolbar
				color="primary"
				dark
				flat
			>
				<v-tabs
					v-model="currentTab"
					centered
					center-active
					show-arrows
				>
					<v-tabs-slider />
					<v-tab
						v-for="tab in tabs"
						:key="tab.name"
					>
						{{ $i18n.t(tab.title) }}
					</v-tab>
				</v-tabs>
			</v-toolbar>

			<v-tabs-items
				v-model="currentTab"
			>
				<!-- association benefits -->
				<v-tab-item v-if="(hideAssociationBenefits === false)">
					<v-container class="container--narrow">
						<v-row v-if="benefits.association.length">
							<v-col
								v-for="item in benefits.association"
								:key="item.id"
								cols="12"
								sm="6"
							>
								<v-card
									height="100%"
								>
									<v-list-item>
										<v-list-item-content>
											<v-list-item-subtitle
												v-if="item._association_title"
											>
												{{ item._association_title }}
											</v-list-item-subtitle>
											<v-list-item-title
												v-if="item.c_company_name"
												class="h5 my-2"
											>
												{{ item.c_company_name }}
											</v-list-item-title>
											<v-list-item-subtitle
												v-if="item.c_discount"
												class="v-list-item__subtitle--multiline"
											>
												{{ item.c_discount }}
											</v-list-item-subtitle>
											<v-list-item-subtitle v-if="item.c_contact_information">
												{{ item.c_contact_information }}
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-avatar
											v-if="item.logo"
											tile
											size="80"
										>
											<v-img
												:src="item.logo.url"
												contain
												position="center top"
											/>
										</v-list-item-avatar>
									</v-list-item>
								</v-card>
							</v-col>
						</v-row>
						<v-alert
							v-else
							type="info"
						>
							{{ $i18n.t('noItemsMessage') }}
						</v-alert>
					</v-container>
				</v-tab-item>
				<!-- union benefits -->
				<v-tab-item>
					<v-container class="container--narrow">
						<v-row v-if="benefits.union.length">
							<v-col
								v-for="item in benefits.union"
								:key="item.id"
								cols="12"
								sm="6"
							>
								<v-card
									:to="{ name: 'benefit', params: { pagename: item.name } }"
									height="100%"
								>
									<v-list-item three-line>
										<v-list-item-content>
											<v-list-item-title
												v-if="item.title"
												class="h5"
											>
												{{ item.title }}
											</v-list-item-title>
											<v-list-item-subtitle
												v-if="item.summary"
											>
												{{ item.summary }}
											</v-list-item-subtitle>
										</v-list-item-content>
										<v-list-item-avatar
											v-if="item.logo"
											tile
											size="80"
										>
											<v-img
												:src="item.logo.url"
												contain
												position="center top"
											/>
										</v-list-item-avatar>
									</v-list-item>
								</v-card>
							</v-col>
						</v-row>
						<v-alert
							v-else
							type="info"
						>
							{{ $i18n.t('noItemsMessage') }}
						</v-alert>
					</v-container>
				</v-tab-item>
			</v-tabs-items>
		</template>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'BenefitContainer',

	data: () => ({
		currentTab: 0,
		tabs: [
			{
				name: 'association',
				title: 'associationBenefits',
			},
			{
				name: 'union',
				title: 'unionBenefits',
			},
		],
		hideAssociationBenefits: false,
	}),

	computed: {
		...mapState({
			user: state => state.user,
			benefits: state => state.benefits,
		}),
	},

	mounted () {
		this.$api.Benefits.doRequest()

		if (this.benefits.association.length < 1) {
			this.hideAssociationBenefits = true
			this.tabs.reverse()
			this.tabs.pop()
		}
	},

	methods: {
		itemClick (item) {
			this.$router.push({ name: 'benefit', params: { pagename: item.name } })
		},
	},
}
</script>
