<i18n>
{
	"en": {
		"buttons": {
			"editYourDetails": "Edit your details"
		},
		"invoices": {
			"paymentInfo": "You can pay the invoice to the bank account <strong>{account}</strong>. Please use the reference number when paying.",
			"title": "Open invoices",
			"label": "Invoice"
		},
		"magazineNotification": {
			"buttonText": "Read more",
			"text": "You have not yet subscribed to Diabetes magazine."
		}
	},
	"fi": {
		"buttons": {
			"editYourDetails": "Katso ja muokkaa tietojasi"
		},
		"invoices": {
			"membershipFees": "Jäsenmaksuni",
			"paymentInfo": "Voit maksaa laskun tilille <strong>{account}</strong>. Käytäthän laskun viitenumeroa maksaessasi.",
			"title": "Avoimet laskut",
			"label": "Lasku"
		},
		"associations": {
			"showPlural": "Näytä yhdistyksieni tiedot",
			"showSingular": "Näytä yhdistykseni tiedot",
			"chairmen": "Puheenjohtajat",
			"chairman": "Puheenjohtaja",
			"memberAttorneys": "Jäsenasiainhoitajat",
			"memberAttorney": "Jäsenasiainhoitaja"
		},
		"magazineNotification": {
			"buttonText": "Tutustu",
			"text": "Et ole vielä tilannut Diabetes-lehteä."
		}
	},
	"sv": {
		"buttons": {
			"editYourDetails": "Ändra dina uppgifter"
		},
		"invoices": {
			"membershipFees": "Min medlemsavgift",
			"paymentInfo": "Du kan betala fakturan på kontot <strong>{account}</strong>. Vänligen ange referensnumret vid betalning.",
			"title": "Obetalda fakturor",
			"label": "Faktura"
		},
		"associations": {
			"showPlural": "Visa mina föreningar",
			"showSingular": "Visa mina föreningar",
			"chairmen": "Ordförande",
			"chairman": "Ordförande",
			"memberAttorneys": "Medlemshandläggare",
			"memberAttorney": "Medlemshandläggare"
		},
		"magazineNotification": {
			"buttonText": "Kolla",
			"text": "Du har ännu inte beställt tidningen Diabetes."
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<v-card>
				<v-img
					v-if="config._membership_card_image"
					class="white--text align-end"
					aspect-ratio="1.628"
					:src="config._membership_card_image.url"
					gradient="rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%"
					dark
				>
					<v-card-title class="headline">
						{{ user._full_name }}
					</v-card-title>
					<v-card-subtitle
						v-if="user.membership.length"
						class="white--text"
					>
						<ul class="no-list">
							<li
								v-for="item in user.membership"
								:key="item.id"
								class="d-block"
							>
								{{ item._list_title }}
							</li>
						</ul>
					</v-card-subtitle>
				</v-img>
				<template v-else>
					<v-card-title class="headline">
						{{ user._full_name }}
					</v-card-title>
					<v-card-subtitle
						v-if="user.membership.length"
						class="white--text"
					>
						<ul class="no-list">
							<li
								v-for="item in user.membership"
								:key="item.id"
								class="d-block"
							>
								{{ item._list_title }}
							</li>
						</ul>
					</v-card-subtitle>
				</template>
				<v-card-text v-if="user._details && user._details.length">
					<FilterableList
						:items="user._details"
						subtitle-src="label"
						title-src="value"
						:enable-click="(false)"
						:enable-search="(false)"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						:to="{ name: 'myAccount' }"
						color="primary"
						text
					>
						<v-icon left>
							mdi-pencil
						</v-icon>
						{{ $i18n.t('buttons.editYourDetails') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

		<!-- 2022-11-23 -->

		<v-container
			v-if="user.membership && user.membership.length"
			class="container--narrow"
		>
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-header>
						<div>
							<v-icon color="primary">
								mdi-receipt
							</v-icon>
							<span class="pl-3">{{ $i18n.t('invoices.membershipFees') }}</span>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<template v-for="invoice in user.invoice">
							<div :key="invoice.id">
								<template v-for="invoice_row in invoice.invoice_row">
									<dl
										class="wide"
										:key="invoice_row.id"
									>
										<dt>{{ invoice_row.title }}</dt>
										<dd>
											<!-- {{ $i18n.t('invoices.label') }}: -->
											<router-link :to="{ name: 'invoice', params: { invoiceId: invoice.id } }">
												{{ invoice._status.text }}
											</router-link>
										</dd>
									</dl>
								</template>
							</div>
						</template>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-container>

		<!-- 2022-11-24 -->

		<!-- Association details -->
		<v-container
			v-if="user._association && Object.keys(user._association).length"
			class="container--narrow"
		>
			<v-expansion-panels>
				<v-expansion-panel>
					<v-expansion-panel-header>
						<div>
							<v-icon color="primary">
								mdi-account-multiple
							</v-icon>
							<span class="pl-3">
								<template v-if="user._association.length > 1">
									{{ $i18n.t('associations.showPlural') }}
								</template>
								<template v-else>
									{{ $i18n.t('associations.showSingular') }}
								</template>
							</span>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<template v-for="association in user._association">
							<div :key="association.id">
								<h3
									v-if="association.title"
									class="text-h6 mb-2"
								>
									{{ association.title }}
								</h3>

								<p
									v-if="association.c_kotisivu"
									class="mb-6"
								>
									<a
										:href="association.c_kotisivu"
										target="_blank"
									>
										{{ formattedUrl(association.c_kotisivu) }}
									</a>
								</p>

								<template
									v-if="association._chairmen && association._chairmen.length"
								>
									<h3 class="text-subtitle-2">
										<template v-if="association._chairmen.length > 1">
											{{ $i18n.t('associations.chairmen') }}
										</template>
										<template v-else>
											{{ $i18n.t('associations.chairman') }}
										</template>
									</h3>
									<FilterableList
										:items="association._chairmen"
										:enable-click="(false)"
										:enable-search="(false)"
										title-src="_full_name"
										summary-src="_summary"
									>
										<template #meta="slotProps">
											<v-row
												no-gutters
											>
												<v-col>
													<v-btn
														v-if="slotProps.item._mobile_link"
														icon
														rounded
														:href="slotProps.item._mobile_link"
													>
														<v-icon color="primary">
															mdi-phone
														</v-icon>
													</v-btn>
												</v-col>
												<v-col>
													<v-btn
														v-if="slotProps.item._email_link"
														icon
														rounded
														:href="slotProps.item._email_link"
													>
														<v-icon color="primary">
															mdi-email
														</v-icon>
													</v-btn>
												</v-col>
											</v-row>
										</template>
									</FilterableList>
								</template>

								<template
									v-if="association._member_attorneys && association._member_attorneys.length"
								>
									<h3 class="text-subtitle-2">
										<template v-if="association._member_attorneys.length > 1">
											{{ $i18n.t('associations.memberAttorneys') }}
										</template>
										<template v-else>
											{{ $i18n.t('associations.memberAttorney') }}
										</template>
									</h3>
									<FilterableList
										:items="association._member_attorneys"
										:enable-click="(false)"
										:enable-search="(false)"
										title-src="_full_name"
										summary-src="_summary"
									>
										<template #meta="slotProps">
											<v-row
												no-gutters
											>
												<v-col>
													<v-btn
														v-if="slotProps.item._mobile_link"
														icon
														rounded
														:href="slotProps.item._mobile_link"
													>
														<v-icon color="primary">
															mdi-phone
														</v-icon>
													</v-btn>
												</v-col>
												<v-col>
													<v-btn
														v-if="slotProps.item._email_link"
														icon
														rounded
														:href="slotProps.item._email_link"
													>
														<v-icon color="primary">
															mdi-email
														</v-icon>
													</v-btn>
												</v-col>
											</v-row>
										</template>
									</FilterableList>
								</template>
							</div>
						</template>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-container>

		<v-container class="container--narrow">
			<v-alert
				type="info"
				class="ma-0"
			>
				Diabetesliiton yhteystiedot löydät <router-link
					:to="{ name: 'page', params: { pagename: 'yhteystiedot' } }"
					class="white--text"
				>
					täältä
				</router-link>.
			</v-alert>
		</v-container>

		<!-- TODO: resolve this later -->
		<!-- <v-container
			v-if="user._magazine_subscription_allowed === true && user._has_magazine_subscription === false"
			class="container--narrow"
		>
			<v-alert
				type="info"
				class="ma-0"
			>
				<v-row
					align="center"
					no-gutters
				>
					<v-col class="grow">
						{{ $i18n.t('magazineNotification.text') }}
					</v-col>
					<v-col
						v-if="typeof magazineBenefitPage === 'object'"
						class="shrink"
					>
						<v-btn
							text
							class="ml-3"
							@click="$router.push({ name: 'benefit', params: { pagename: magazineBenefitPage.name } })"
						>
							{{ $i18n.t('magazineNotification.buttonText') }}
						</v-btn>
					</v-col>
				</v-row>
			</v-alert>
		</v-container> -->

		<!-- <v-container
			v-if="user._open_invoices && user._open_invoices.length"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('invoices.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-alert type="info">
						<span
							v-html="$i18n.t('invoices.paymentInfo', {
								account: 'FI03 5732 2620 0278 13',
							})"
						/>
					</v-alert>
					<FilterableList
						:items="user._open_invoices"
						icon-src="_icon"
						icon-color-src="_icon_color"
						title-src="_list_title"
						summary-src="_list_summary"
						:multiline="true"
						:enable-click="(false)"
						:enable-search="(false)"
					/>
				</v-card-text>
			</v-card>
		</v-container> -->
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'MembershipCard',
	computed: {
		...mapState([
			'config',
			'user',
			'benefits',
		]),
		// magazineBenefitPage () {
		// 	return this.benefits.find(item => item.id === 1176)
		// },
	},

	mounted () {
		this.$api.Me.doRequest()
	},

	methods: {
		formattedUrl (val) {
			let url = new URL(val)
			return url.hostname
		},
	},
}
</script>

<style lang="scss" scoped>
dl {
	display: grid;
	// grid-template-columns: max-content auto;
	grid-template-columns: 1fr auto;
	column-gap: 1rem;
	row-gap: 0.5rem;

	+ dl {
		margin-top: 1rem;
	}

	&.wide {
		justify-content: space-between;
	}

	h4 {
		grid-column-start: 1;
		grid-column-end: 2;
	}

	dt {
		grid-column-start: 1;
	}

	dd {
		grid-column-start: 2;
	}
}
</style>
